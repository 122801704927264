<template>
    <div>
        <div class="fl-te-t">
            <div class="card bs-4 p-0" style="width:25%">
                <listing-component @familyChange="loadDetails" :data="data"/>
            </div>
            <div class="card bs-4 mt-0" style="width:74%">
                <details-page :id="id.toString()" @itemChange="itemChange"></details-page>
            </div>
        </div>
    </div>
</template>

<script>
import ListingComponent from '@/views/CatalogueAdmin/DataStructure/Components/ListingComponent';
import DetailsPage from '@/views/CatalogueAdmin/DataStructure/Components/DetailsPage';

export default {
    name : 'dataStructure',
    data () {
        return {
            data : {},
            id   : ''
        };
    },
    methods : {
        loadDetails (response) {
            this.id = response;
        },
        itemChange (response) {
            this.data = response;
        }
    },
    components : { DetailsPage, ListingComponent }
};
</script>

<style scoped>

</style>
