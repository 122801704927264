<template>
    <loading-animation class="p-4" v-if="ListLoading"></loading-animation>
    <div v-else class="mb-3">
        <div class="fl-te-c bb-2 mb-3 p-2">
            <h5>Families</h5>
            <btn color="primary" class="btn-xs" icon="fa fa-plus" @click="$refs.addModal.show()" text="Add"></btn>
        </div>
        <div v-if="family">
            <div v-for="record in family" v-bind:key="record.id">
                <div v-if="selected===record.id" style="cursor: pointer;" @click="itemClicked(record)"
                     class="p-2 box fl-te-c bg-primary">
                    {{ record.name }}
                </div>
                <div v-if="selected!==record.id" style="cursor: pointer;" @click="itemClicked(record)"
                     class="p-2 box fl-te-c">
                    {{ record.name }}
                </div>
            </div>
        </div>
        <div v-if="family.length===0" class="text-center bg-primary card ml-3 mr-3">Please add a family.</div>

        <modal no-close-on-backdrop title="Add Family" ref="addModal" width="30r">
            <b-form :save-url="addUrl" @success="addFormSuccess" v-slot="{model, loading}">
                <div class="row mb-2">
                    <div class="col">
                        <validated-input placeholder="Name" v-model="model.name" :disabled="loading"
                                         :rules="rules.name"/>
                    </div>
                </div>
                <btn size="xs" text="Save" :loading="loading" loading-text="Saving..."></btn>
            </b-form>
        </modal>
    </div>
</template>

<script>
import urls from '../../../../data/urls';
import axios from 'secure-axios';

export default {
    name  : 'ListingComponent',
    props : { data : { type : Object } },
    data () {
        return {
            ListLoading : true,
            addUrl      : urls.catalogueAdmin.attributeFamily.add,
            family      : null,
            selected    : false,
            id          : '',
            rules       : {
                name : {
                    required : true
                }
            }
        };
    },
    mounted () {
        this.loadDetails();
    },
    watch : {
        data : {
            handler () {
                this.family = this.data.data;
            }
        }
    },
    methods : {
        addFormSuccess (response) {
            this.family = response.data.family.data;
            this.$refs.addModal.close();
        },
        async loadDetails () {
            const that = this;
            that.ListLoading = true;
            const response = await axios.get(urls.catalogueAdmin.attributeFamily.list);
            const json = response.data;
            that.family = json.data;
            that.ListLoading = false;
        },
        itemClicked (data) {
            if (this.selected === data.id) {
                this.selected = '';
            } else {
                this.selected = data.id;
            }
            this.$emit('familyChange', data.id);
        }
    }
};
</script>

<style scoped>
.box:hover {
    background-color: #f3639e;
    color: white;
}
</style>
