<template>
    <div>
        <loading-animation v-if="DataLoading"></loading-animation>
        <!--    Attribute Family    -->
        <div v-else>
            <div v-if="data" class="fl-te-c ml-2 mt-2 mb-4">
                <div class="h4">{{ data.name }}
                    <edit-btn class="btn-basic-b" @click="setEdit" text=""/>
                    <edit-btn icon="fa fa-trash" @click="setDelete" class="btn-basic-b ml-1" text=""/>
                </div>
                <div class="btn-group">
                    <btn size="sm" text="Save" @click="onSaveClicked" :loading="loading" loading-text="Saving..."></btn>
                </div>
            </div>

            <draggable v-if="data" :list="data.sections" @change="log" handle=".handle1">
                <div v-for="(i, index1) in data.sections" v-bind:key="i.name" class="card b-2 bgc-tertiary p-0 mt-3">
                    <div class="fl-te-c p-3">
                        <h5>{{ i.name }}</h5>
                        <div class="btn-group">
                            <btn class="btn-basic-b" color="primary" @click="showSectionEditModal(i.name, index1)"
                                 icon="fa fa-pencil" size="xs" text="Edit"/>
                            <btn class="btn-basic-b handle1" color="primary" icon="fa fa-sort" size="xs"
                                 text="Order"/>
                            <btn class="btn-basic-b" color="primary" @click="showAttributeAddModal(index1)"
                                 icon="fa fa-plus" size="xs" text="Add"/>
                            <btn class="btn-basic-b" color="primary" @click="deleteSection(i.name, index1)"
                                 icon="fa fa-trash" size="xs" text="Delete"/>
                        </div>
                    </div>
                    <!--    Section Attributes    -->
                    <table class="table catalogue-table table-borderless" ref="table">
                        <thead>
                        <tr>
                            <th class="w-15r text-left">Data</th>
                            <th class="w-10r text-left">Type</th>
                            <th>Filterable</th>
                            <th>Visible</th>
                            <th>Comparable</th>
                            <th>Configurable</th>
                            <th class="bgc-tertiary-dark">Actions</th>
                        </tr>
                        </thead>
                        <draggable tag="tbody" :list="i.attributes" @change="log" group="attributes" handle=".handle">
                            <tr v-for="(attribute, index2) in i.attributes" v-bind:key="attribute.name">
                                <td>
                                    <div style="word-wrap: break-word" class="col w-15r">{{ attribute.name }}</div>
                                </td>
                                <td>
                                    <div class="col w-10r">{{ attribute.type }}</div>
                                </td>
                                <td>
                                    <validated-vue-select size="md" :clearable="false" :select-first="true"
                                                          :options="confirmOptions"
                                                          name="Filterable" :rules="{required: true}"
                                                          v-model="attribute.filterable"
                                                          :disabled="false" class="mb-0">
                                    </validated-vue-select>
                                </td>
                                <td>
                                    <validated-vue-select size="md" :clearable="false" :select-first="true"
                                                          :options="confirmOptions"
                                                          name="Visible On Web" :rules="{required: true}"
                                                          v-model="attribute.visible_on_web"
                                                          :disabled="false" class="mb-0">
                                    </validated-vue-select>
                                </td>
                                <td>
                                    <validated-vue-select size="md" :clearable="false" :select-first="true"
                                                          :options="confirmOptions"
                                                          name="Comparable" :rules="{required: true}"
                                                          v-model="attribute.comparable"
                                                          :disabled="false" class="mb-0">
                                    </validated-vue-select>
                                </td>
                                <td>
                                    <validated-vue-select size="md" :clearable="false" :select-first="true"
                                                          :options="confirmOptions"
                                                          name="Configurable" :rules="{required: true}"
                                                          v-model="attribute.configurable"
                                                          :disabled="false" class="mb-0">
                                    </validated-vue-select>
                                </td>
                                <td>
                                    <div class="btn-group">
                                        <btn class="btn-basic-b handle" color="primary" icon="fa fa-sort"
                                             size="sm"/>
                                        <btn class="btn-basic-b"
                                             @click="deleteAttribute(attribute.name, index1, index2)"
                                             color="primary" icon="fa fa-trash" size="sm"/>
                                        <btn class="btn-basic-b"
                                             @click="showAttributeEditModal(attribute.name, index1, index2)"
                                             color="primary" icon="fa fa-pencil" size="sm"/>
                                    </div>
                                </td>
                            </tr>
                        </draggable>
                    </table>
                </div>

            </draggable>

            <!--    Add Section    -->
            <div v-if="data">
                <btn class="mt-4" color="primary" icon="fa fa-plus" size="xs" @click="showSectionAddModal" text="Add"/>
            </div>

            <!--    Form Modals    -->

            <modal no-close-on-backdrop title="Section" ref="sectionAddModal" width="30r">
                <s-form @submit="addSection" ref="sectionFormModal">
                    <validated-input placeholder="Section Name" name="Section" :rules="rules.section_name"
                                     v-model="section_name"/>
                    <save-btn type="submit" icon="" size="xs" color="primary" text="Save"/>
                </s-form>
            </modal>

            <modal no-close-on-backdrop title="Edit Section" ref="sectionEditModal" width="30r">
                <s-form @submit="editSection" ref="sectionFormModal">
                    <validated-input placeholder="Section Name" name="Section" :rules="rules.section_name"
                                     v-model="section_name"/>
                    <save-btn type="submit" icon="" size="xs" color="primary" text="Update"/>
                </s-form>
            </modal>

            <modal title="Attribute" ref="attributeAddModal" width="30r" no-close-on-backdrop>
                <s-form @submit="addAttribute" ref="attributeFormModal">

                    <validated-input placeholder="Attribute Name" name="Attribute" :rules="rules.attribute_name"
                                     v-model="attribute_name"/>
                    <validated-select size="md" :clearable="false" :select-first="true"
                                      :options="typeOptions"
                                      name="Type" :rules="{required: true}"
                                      v-model="attribute_type"
                                      :disabled="false" class="mb-2">
                    </validated-select>
                    <save-btn type="submit" icon="" size="xs" color="primary" text="Save"/>
                </s-form>
            </modal>

            <modal no-close-on-backdrop title="Attribute" ref="attributeEditModal" width="30r">
                <s-form @submit="editAttribute" ref="attributeFormModal">

                    <validated-input placeholder="Attribute Name" name="Attribute" :rules="rules.attribute_name"
                                     v-model="attribute_name"/>
                    <save-btn type="submit" icon="" size="xs" color="primary" text="Update"/>
                </s-form>
            </modal>

            <delete-modal dialog ref="deleteAttributeModal">
                <p>You are about to delete the attribute <b> {{ attribute_name }}</b>.Are you sure
                    ?</p>
            </delete-modal>

            <delete-modal dialog ref="deleteSectionModal">
                <p>You are about to delete the section <b> {{ section_name }}</b>.Are you sure
                    ?</p>
            </delete-modal>

            <div v-if="!data" class="text-center bg-primary card">Please choose a family.</div>

            <modal no-close-on-backdrop title="Edit Family" ref="editModal" width="30r">
                <b-form :save-url="editUrl" ref="editFormModal" @success="editFormSuccess" v-slot="{model, loading}"
                        :initial-data="editingItem">
                    <div class="row">
                        <div class="col">
                            <validated-input placeholder="Name" v-model="model.name" :disabled="loading"
                                             :rules="rules.name"/>
                        </div>
                    </div>
                    <btn size="xs" text="Update" :loading="loading" loading-text="Updating..."></btn>
                </b-form>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the attribute family <b v-html="deletingItem && deletingItem.name"></b>. Are
                    you
                    sure ?</p>

                <template #loading>
                    <delete-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingConfirm.name"></b>.
                </template>
            </delete-modal>
            <delete-modal ref="deleteConfirmModal" :url="deleteUrl" :params="deletingConfirm" @response="deleteComplete1">
                <p>There are certain products in the family. Do you wish to continue?</p>
                <template #loading>
                    <delete-animation/>
                    Please wait while we delete <b v-html="deletingConfirm && deletingConfirm.name"></b>.
                </template>
            </delete-modal>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import urls from '../../../../data/urls';
import axios from 'secure-axios';

export default {
    props : {
        id : { type : String }
    },
    name       : 'DetailsPage',
    components : {
        draggable
    },
    data () {
        const that = this;
        return {
            loading      : false,
            DataLoading  : false,
            errorMessage : '',
            editUrl      : urls.catalogueAdmin.attributeFamily.edit,
            deleteUrl    : urls.catalogueAdmin.attributeFamily.delete,
            editingItem  : {
                id   : '',
                name : ''
            },
            deletingItem : {
                confirm_delete : false,
                id             : '',
                name           : ''
            },
            deletingConfirm : {
                confirm_delete : true,
                id             : '',
                name           : ''
            },
            family_id      : '',
            index1         : '',
            index2         : '',
            section_name   : '',
            currentName    : '',
            attribute_name : '',
            attribute_type : '',
            attributeList  : [],
            sectionList    : [],
            data           : '',
            typeOptions    : [
                { value : 'text', label : 'Text' },
                { value : 'textarea', label : 'Text Area' },
                { value : 'number', label : 'Number' },
                { value : 'checkbox', label : 'Check Box' },
                { value : 'date', label : 'Date' },
                { value : 'color', label : 'Color' }
            ],
            confirmOptions : [
                { value : true, label : 'Yes' },
                { value : false, label : 'No' }
            ],
            rules : {
                section_name : {
                    required        : true,
                    customValidator : (value) => {
                        return that.sectionNameExists(value);
                    }
                },
                attribute_name : {
                    required        : true,
                    customValidator : (value) => {
                        return that.attributeNameExists(value);
                    }
                },
                family : {
                    required : true
                }
            }
        };
    },
    watch : {
        id : {
            handler () {
                this.loadDetails();
            }
        }
    },
    methods : {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            that.sectionList = [];
            that.attributeList = [];
            const response = await axios.form(urls.catalogueAdmin.attributeFamily.details, { family : that.id });
            const json = response.data;
            if (json.error === false) {
                that.data = json.data;
                that.family_id = json.data._id;
                that.DataLoading = false;
                that.setSectionList();
                that.setAttributeList();
            } else {
                that.data = '';
                that.family_id = '';
                that.DataLoading = false;
                that.$notify('No details available', 'Success', {
                    type : 'info'
                });
            }
        },
        setSectionList () {
            const data = this.data.sections;
            for (let i = 0; i < data.length; i++) {
                const name = data[i].name.replace(/ /g, '');
                this.sectionList.push(name.toLowerCase());
            }
        },
        setAttributeList () {
            const data = this.data.sections;
            for (let i = 0; i < data.length; i++) {
                const section = data[i];
                for (let j = 0; j < section.attributes.length; j++) {
                    const attribute = section.attributes[j].name.replace(/ /g, '');
                    this.attributeList.push(attribute.toLowerCase());
                }
            }
        },
        async onSaveClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.catalogueAdmin.attributeFamily.saveFamilyDetails, {
                family_id : that.family_id,
                json      : JSON.stringify(that.data)
            });
            const json = response.data;
            if (json.error === false) {
                that.$notify('Saved Successfully', 'Success', {
                    type : 'success'
                });
                that.loading = false;
            } else if (json.errors) {
                that.$notify(json.errors.__all__, 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            } else {
                that.$notify('Unable to save details..Please try again later!', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            }
        },

        setEdit () {
            this.editingItem.id = this.data._id;
            this.editingItem.name = this.data.name;
            this.$refs.editModal.show();
        },
        editFormSuccess (response) {
            this.data.name = this.$refs.editFormModal.getModelValue('name');
            this.$emit('itemChange', { data : response.data.family.data });
            this.$refs.editModal.close();
        },
        setDelete () {
            this.deletingItem.id = this.data._id;
            this.deletingItem.name = this.data.name;
            this.deletingConfirm.id = this.data._id;
            this.deletingConfirm.name = this.data.name;
            this.$refs.deleteModal.show();
        },
        deleteComplete (response) {
            if (response.data.msg === 'Product exists for the family') {
                this.$refs.deleteModal.close();
                this.$refs.deleteConfirmModal.show();
            } else {
                this.deleteComplete1(response);
            }
        },
        deleteComplete1 (response) {
            this.data = '';
            this.$emit('itemChange', { data : response.data.family.data });
            this.deletingItem.id = '';
            this.deletingItem.name = '';
            this.deletingItem.confirm_delete = false;
            this.$refs.deleteModal.close();
            this.$refs.deleteConfirmModal.close();
        },

        showSectionAddModal () {
            this.section_name = '';
            this.currentName = '';
            this.$refs.sectionAddModal.show();
        },
        addSection () {
            const sections = this.data.sections;
            const response = this.checkDuplicate(this.section_name, this.sectionList);
            if (response !== false) {
                sections.push({
                    id         : '',
                    name       : this.section_name,
                    attributes : []
                });
                this.sectionList = [];
                this.setSectionList();
                this.$refs.sectionAddModal.close();
                this.section_name = '';
            } else {
                const errorMessage = this.section_name + ' already exists!';
                this.$refs.sectionFormModal.setErrors({
                    Section : errorMessage
                });
            }
        },
        showSectionEditModal (name, index1) {
            this.index1 = index1;
            this.section_name = name;
            this.currentName = name.replace(/ /g, '').toLowerCase();
            this.$refs.sectionEditModal.show();
        },
        editSection () {
            const response = this.checkDuplicate(this.section_name, this.sectionList);
            if (response !== false) {
                this.data.sections[this.index1].name = this.section_name;
                this.sectionList = [];
                this.setSectionList();
                this.$refs.sectionEditModal.close();
                this.section_name = '';
                this.currentName = '';
            } else {
                const errorMessage = this.section_name + ' already exists!';
                this.$refs.sectionEditModal.setErrors({
                    Section : errorMessage
                });
            }
        },
        deleteSection (name, index1) {
            this.section_name = name;
            const data = this.data.sections;
            data.splice(index1, 1);
            this.section_name = '';
            this.sectionList = [];
            this.setSectionList();
        },

        showAttributeAddModal (index1) {
            this.index1 = index1;
            this.attribute_name = '';
            this.attribute_type = '';
            this.currentName = '';
            this.$refs.attributeAddModal.show();
        },
        addAttribute () {
            const attributes = this.data.sections[this.index1].attributes;
            const response = this.checkDuplicate(this.attribute_name, this.attributeList);
            if (response !== false) {
                attributes.push({
                    id             : '',
                    name           : this.attribute_name,
                    type           : this.attribute_type,
                    filterable     : '',
                    visible_on_web : '',
                    comparable     : '',
                    configurable   : ''

                });
                this.attributeList = [];
                this.setAttributeList();
                this.$refs.attributeAddModal.close();
                this.attribute_name = '';
                this.attribute_type = '';
                this.index1 = '';
            } else {
                const errorMessage = this.attribute_name + ' already exists!';
                this.$refs.attributeAddModal.setErrors({
                    Attribute : errorMessage
                });
            }
        },
        showAttributeEditModal (name, index1, index2) {
            this.index1 = index1;
            this.index2 = index2;
            this.attribute_name = name;
            this.currentName = name.replace(/ /g, '').toLowerCase();
            this.$refs.attributeEditModal.show();
        },
        editAttribute () {
            const response = this.checkDuplicate(this.attribute_name, this.attributeList);
            if (response !== false) {
                this.data.sections[this.index1].attributes[this.index2].name = this.attribute_name;
                this.attributeList = [];
                this.setAttributeList();
                this.$refs.attributeEditModal.close();
                this.attribute_name = '';
                this.currentName = '';
            } else {
                const errorMessage = this.attribute_name + ' already exists!';
                this.$refs.attributeEditModal.setErrors({
                    Attribute : errorMessage
                });
            }
        },
        deleteAttribute (name, index1, index2) {
            this.attribute_name = name;
            const data = this.data.sections[index1].attributes;
            data.splice(index2, 1);
            this.attribute_name = '';
            this.attributeList = [];
            this.setAttributeList();
        },

        sectionNameExists (value) {
            const val = this.checkDuplicate(this.section_name, this.sectionList);
            if (!val) {
                return 'The ' + value + ' already exists.';
            }
            return true;
        },
        attributeNameExists (value) {
            const val = this.checkDuplicate(this.attribute_name, this.attributeList);
            if (!val) {
                return 'The ' + value + ' already exists.';
            }
            return true;
        },
        checkDuplicate (name, array) {
            name = name.replace(/ /g, '');
            if (array.length !== 0 && (this.currentName.replace(/ /g, '').toLowerCase() !== name.toLowerCase())) {
                for (let i = 0; i <= array.length; i++) {
                    if (array[i] === name.toLowerCase()) {
                        return false;
                    }
                }
            }
            return true;
        },
        log : function (evt) {
            window.console.log(evt);
        }
    }
};
</script>

<style>
.catalogue-table th {
    background-color: #eeeeee !important;
    color: #6c757d;
}

.table.table-borderless tr td .mb-form {
    margin-bottom: 0 !important;
}

.table.table-borderless td {
    padding: 5px;
    vertical-align: middle;
}

/*.my-form, .mb-form {*/
/*    margin-bottom: 0 !important;*/
/*}*/

.table.table-borderless th {
    text-align: center;
}

.form-check input {
    margin-right: 0;
}

.table {
    margin-bottom: 0 !important;
}

tbody:empty {
    height: 2rem;
}
</style>
